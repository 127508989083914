.gameslist-table {
    border: 1px solid;
    height: 100px;
    overflow: auto;
    width: 500px;
}

.gamelist-row {
    padding-top: 5px;
    padding-bottom: 5px;
}


.gamelist-row:nth-child(even) {
    background-color: lightslategray;
}

.gamelist-row div {
    display: inline-flex;
    padding-right: 19px;
}

.btn-play {
    background-color: green;
    border: 1px;
    border-radius: 3px;
    color: white;
    font-size: 16px;
    padding-left: 7px;
    padding-right: 7px;
    box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -webkit-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -moz-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
}

.menu-spacer {
    height: 20px;
    width: 40px;
}