.player-details {
    text-align: left;
    margin-top: 8px;
    margin-left: 8px;
    display: flex;
    min-height: 96px;
}

.player-details-inner {
    margin-left: 8px;
}

.player-details-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.playerself > div {
    max-width: 150px;
}

.player-name-input {
    width: 100%;
    background-color: #1d2d3c;
    color: white;
    border: 1px solid lightgreen;
    font-size: 21px;
    border-radius: 5px;
}

.playerself {
    /* display: inline-flex; */
}

/* Mobile divide by 4 */
@media (max-width: 480px) {
    .player-details-inner {
        margin-right: 5%;
    }
    
    .player-details-list {
        font-size: 11px !important;
    }
}