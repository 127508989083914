.bidInput {
    position: absolute;
    left: 45%;
    top: -72px;
    padding: 11px;
    background-color:#008a94;
    border-radius: 7px;
    border-left: 1px solid #1d2d3c;
    border-bottom: 2px solid #1d2d3c;
    border-right: 1px solid #1d2d3c;
    min-width: 177px;
}

.bidInputBox {
    width: 48px;
    margin-right: 12px;
    height: 58px;
    font-size: 20px;
    padding: 3px;
}

.btn-bid {
    background-color: darkslategray;
    padding: 10px;
    color: white;
    border: 1px;
    border-radius: 7px;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -webkit-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -moz-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
}

.btn-bid:hover {
    background-color: #306666;
}

.bidInputWrapper {
    display: inline-flex;
}

.btn-inputbox {
    position: absolute;
    width: 25px;
    height: 24px;
    border: 1px solid #686868;
    border-radius: 4px;
    left: 56px;
    text-align: center;
    padding: 0;
    font-size: 14px;
}

.btn-down {
    top: 46px;
}

.btn-up {
    top: 23px;
}

.bidCantSend p {
    margin: 0;
    margin-top: 6px;
}