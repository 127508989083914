.btn-ready {
    background-color: green;
    padding: 16px;
    color: white;
    border: 1px;
    border-radius: 7px;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    transition: all 2s;
    box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -webkit-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -moz-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
}

.btn-ready:hover {
    background-color: #1aaa1a;
}

.btn-ready-countdown {
    float: right;
    padding-left: 6px;
}

.readyPos {
    margin-left: 13vw;
}

.continuePos {
    margin-left: 9vw;
    text-align: center;
}