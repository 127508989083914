.player-avatar-container {
}

.player-avatar {
    width: 2vw;
}

/* Tablets */
@media (max-width: 768px) {
    .player-avatar {
        width: 4vw;
    }
}

/* Mobile divide by 4 */
@media (max-width: 480px) {
    .player-avatar {
        width: 6vw;
    }
} 