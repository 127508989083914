.card {
    height: 136px;
    display: inline-block;
    cursor: pointer;
    -webkit-box-shadow: 4px 4px 12px -4px rgba(0,0,0,0.83); 
    box-shadow: 4px 4px 12px -4px rgba(0,0,0,0.83);
}

/* Tablets divide by 2.5 */
@media (max-width: 768px) {
    .card {
        height: 13vw;
    }
}
