.hand-container {
    width: 83%;
}

.hand-inner {
    min-width: 100%;
    max-width: 164px;
    height: 143px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    background-color: #8a8a8a;
    border-radius: 3px;
    padding: 8px;
    display: inline-flex;
}

.hand-card {
    padding: 5px;
    display: flex;
    
}

.card {
    transition: all 0.13s;
    -webkit-transition: all 0.13s;
    -moz-transition: all 0.13s;
}

.playable img:hover {
    transform: translateY(-8px);
}

.unplayable img {
    opacity: 0.5;
    cursor: default;
}

/* Tablets */
@media (max-width: 1024px) {
    .hand-inner {
        width: 51vw !important;
    }

}

/* Tablets divide by 2.5 */
@media (max-width: 768px) {
    .hand-container {
        width: 75%;
    }
}

/* Mobile divide by 4 */
@media (max-width: 480px) {
    .hand-card img {
        height: 29vw;
    }

    .hand-container {
        width: 68%;
    }
}