.scores {
    display: inline-flex;
    overflow-y: scroll;
    height: 231px;
    margin-top: 10px;
}

.scoreboard {
    position: absolute;
    top: 25%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    border-style: solid;
    padding: 10px;
    background-color: white;
    color: black;
    min-width: 240px;
    box-shadow: 5px 5px 23px rgba(0, 0, 0, 0.8)  ; 
	-webkit-box-shadow: 5px 5px 23px rgba(0, 0, 0, 0.8)  ; 
	-moz-box-shadow: 5px 5px 23px rgba(0, 0, 0, 0.8)  ; 
}

.score-cell.player-tricks {
    background-color: #9eabb9;
}

.scoreBoardButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: 1px solid black;
    font-size: 19px;
    font-weight: bolder;
    cursor: pointer;
}

.player-score-header {
    padding: 7px;
    border: 1px solid;
}

.player-turn {
    display: flex;
}

.player-bid:nth-child(even),
.player-score:nth-child(even),
.player-score-header:nth-child(even) {
    background-color: lightslategray;
}

.player-score {
    flex: 1;
}

.player-bid,
.player-tricks {
    flex: 0;
}

.score-cell {
    display: inline-flex;
    padding: 5px;
    border: 1px solid;
    font-family: sans-serif;
}

.player-column-header {
    font-size: small;
}

.legend {
    position: absolute;
    top: 10px;
}

.legend-line {
    display: flex;
}

.legend-box {
    height: 10px;
    width: 10px;
    border: 1px solid black;
    margin-right: 6px;
    padding: 2px;
}

.legend-score {
    background-color: white;
}

.legend-bid {
    background-color: lightslategray;
}

.legend-trick {
    background-color: #9eabb9;
}

.scores-roundcounter {
    margin-top: 36px;
}

.scores-roundcounter-cell {
    display: grid;
    border: 1px solid black;
    padding: 5px;
    font-family: sans-serif;
    background-color: black;
    color: white;
}