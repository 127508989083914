.trump-card {
    float: right;
    margin-top: -73px;
    z-index: 10000;
}

.trump-card-inner {
    margin-top: 11px;
    width: 112px;
    height: 179px;
    border: 1px solid black;
    border-radius: 7px;
    background-color: #8a8a8a;
}

.trump-card p {
    margin: 0;
    font-weight: bold;
    font-size: 20px;
}   

.trump-card div img {
    height: 175px;
    cursor: default;
}
