.chat-message-wrapper {
    margin: 10px;
    padding: 5px;
    text-align: left;
    background-color: #1716160a;
}

.chat-player {
    font-weight: 700;
    text-align: left;
}

.message-date {
    font-size: 9px;
    text-align: left;
}

.chat-player {
    display: inline-flex;
}

.player-message {
    display: inline-flex;
    margin-left: 6px;
}