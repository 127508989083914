.t {
    position: absolute;
    top: 23em;
    left: 50%;
}

.t > div {
    position: absolute;
    max-width: 300px;
    max-height: 300px;
    z-index: -1;
    transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0px 0;
    transition: border-left-color 0.33s;
    -webkit-transition: border-left-color 0.33s;
    -moz-transition: border-left-color 0.33s;
    border-top: 219px solid transparent !important;
    border-bottom: 215px solid transparent !important;
    border-left-width: 371px !important;
    /* border-top: 22.4092vh solid transparent;
    border-bottom: 22vh solid transparent;
    border-left: 37.9632vh solid; */
    border-left-color: #1c1b1b;
    border-left-style: solid;
}

/* Ratio of angles 1.0186: 1 : 1.7256 */

/* Macs */
@media (max-width: 1440px) {
    .t {
        top: 21em;
    }
    .t > div {
        border-top: 196.3px solid transparent !important;
        border-bottom: 193px solid transparent !important;
        border-left-width: 331px !important;
    }
    .t div .player {
        top: -56px !important;
        right: 115px !important;
        width: 211px !important;
    }
}

/* Laptops divide by 1.875 */
@media (max-width: 1024px) {
    .t {
        top: 22em;
    }
    .t > div {
        border-top: 196.3px solid transparent !important;
        border-bottom: 193px solid transparent !important;
        border-left-width: 331px !important;
    }
    .t div .player {
        top: -55px !important;
        right: 116px !important;
    }
}

/* Tablets divide by 2.5 */
@media (max-width: 768px) {
    .t {
        top: 18em;
    }
    .t > div {
        border-top: 162.9px solid transparent !important;
        border-bottom: 156px solid transparent !important;
        border-left-width: 275px !important;
    }
    .t div .player {
        top: -59px !important;
        right: 125px !important;
        width: 121px !important;
    }
}

/* Mobile divide by 4 */
@media (max-width: 480px) {
    .t > div {
        border-top: 132px solid transparent !important;
        border-bottom: 130px solid transparent !important;
        border-left-width: 224px !important;
    }
    .t div .player {
        top: -68px !important;
        right: 93px !important;
        width: 128px !important;
    }
}

.t > div:nth-child(1) {
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}

.t > div:nth-child(2) {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.t > div:nth-child(3) {
    -ms-transform: rotate(-210deg);
    -webkit-transform: rotate(-210deg);
    transform: rotate(-210deg);
}

.t > div:nth-child(4) {
    -ms-transform: rotate(-150deg);
    -webkit-transform: rotate(-150deg);
    transform: rotate(-150deg);
}

.t > div:nth-child(5) {
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.t > div:nth-child(6) {
    -ms-transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
}

.t div .player {
    background: #17161600;
    position: absolute;
    top: -43px;
    right: 136px;
    width: 205px;
}

.t > div:nth-child(1) .player {
    -ms-transform: rotate(-30deg) skewX(0deg);
    -webkit-transform: rotate(-30deg) skewX(0deg);
    transform: rotate(-30deg) skewX(0deg);
}

.t > div:nth-child(2) .player {
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.t > div:nth-child(3) .player {
    -ms-transform: rotate(-150deg);
    -webkit-transform: rotate(-150deg);
    transform: rotate(-150deg);
}

.t > div:nth-child(4) .player {
    -ms-transform: rotate(-210deg) skewX(0deg);
    -webkit-transform: rotate(-210deg) skewX(0deg);
    transform: rotate(-210deg) skewX(0deg);
}

.t > div:nth-child(5) .player {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.t > div:nth-child(6) .player {
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}

.notReady {
     border-left-color: #8a8a8a !important;
}

.queuedForDeal {
     border-left-color: red !important;
}

.dealt {
     border-left-color: red !important;
}

.queuedForBid {
     border-left-color: #2f4858 !important;
}

.bidding {
     border-left-color: #008a94 !important;
}

.bid {
     border-left-color: #2f4858 !important;
}

.queuedForPlay {
     border-left-color: darkgreen !important;
}

.playing {
     border-left-color: #79ac79 !important;
}

.played {
     border-left-color: darkgreen !important;
}

.won {
     border-left-color: #b59a0b !important;
}

.lost {
     border-left-color: #f06070 !important;
}

.hidden {
    display: none;
}

.trickWinner {
    border-left-color: #b59a0b !important;
}

.ready {
    border-left-color: green !important;
}