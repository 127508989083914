.btn-scoreboardToggle {
    background: black;
    border: 0;
    padding: 7px;
    border-radius: 6px;
    color: white;
    position: absolute;
    left: -4px;
    top: 47%;
    cursor: pointer;
}

.btn-scoreboardToggle img {
    height: 32px;
}

.gameControls {
    position: absolute;
    bottom: 0;
    background-color: #0e0f11;
    width: 100%;
}

.round-counter {
    position: absolute;
    right: 20%;
    top: 21px;
    font-size: 25px;
    color: #ffffffa3;
}

.game-winner {
    position: absolute;
    text-align: center;
    width: 100%;
}