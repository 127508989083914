.pile-container {
    position: absolute;
    left: 50%;
    top: 24em;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.pile {
    background-color: #aeaaaa;
    border-radius: 50%;
    height: 30vh;
    width: 30vh;
    -webkit-box-shadow: 2px 1px 2px 2px rgba(0,0,0,0.15); 
    box-shadow:2px 1px 2px 2px rgba(0,0,0,0.15);
    z-index: 100;
}

.pile-card{
    position: absolute;
}

/* Mac */
@media (max-width: 1440px) {
    .pile-container {
        top: 21em;
    }

    .pile {
        height: 27vh;
        width: 27vh;
    }
}

/* Tablets */
@media (max-width: 768px) {
    .pile-container {
        top: 18em;
    }

    .pile {
        height: 30vw;
        width: 30vw;
    }
}

/* Mobile divide by 4 */
@media (max-width: 480px) {
    .pile {
        height: 30vw;
        width: 30vw;
    }
}