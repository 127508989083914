.chat-ext-wrapper {
    position: fixed;
    right: -662px;
    z-index: 2;
    height: 100%;
    background-color: #000000d9;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    max-width: 555px;
    min-width: 429px;
    overflow: hidden;
    z-index: 1000001;
}

.chat-unfolded {
    right: 3px !important;
}

.chat-container {
    border-style: solid;
    height: 96%;
    overflow: auto;
    border: 0;
}

.chat-wrapper {
    display: table-cell;
    vertical-align: bottom;
    height: 248px;
    width: 555px;
}

.chaticon {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1000002;
}
