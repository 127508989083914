.chat-input-container {
    margin-top: -10px;
}


.msgInput {
    width: 100%;
}

.msgInputBox {
    width: 96%;
    background: #1c1b1b;
    color: white;
    height: 33px;
    border: 0;
    margin-right: 5px;
    font-size: 21px;
    padding-left: 6px;
}

.btn-chat {
    border: 1px solid #686868;
    border-radius: 4px;
    text-align: center;
    padding: 6px;
    font-size: 19px;
    background: #fffefe;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: -13px;
    color: #000;
    border: 0;
    cursor: pointer;
    box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -webkit-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -moz-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
}