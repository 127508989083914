.playerControls {
    background-color: #1e2122;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    max-width: 1000px;
    height: 272px;
    margin-left: 23vw;
    border-left: 3px solid #373737;
    border-right: 3px solid #2b2a2a;
    margin-top: -74px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

@media (max-width: 1500px) {
    .playerControls {
        margin-left: 15% !important;
    }
}

/* Laptops divide by 1.875 */
@media (max-width: 1024px) {
    .playerControls {
        margin-left: 0 !important;
    }
}

/* Mobile divide by 4 */
@media (max-width: 480px) {
    .playerControls {
        margin-left: 0 !important;
    }
}