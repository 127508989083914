.mainmenu-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: #111517;
    padding: 9vh;
}

.mainmenu-inner {
    margin-bottom: 25px;
}

.btn-mainmenu {
    margin-left: 10px;
    background-color: green;
    padding: 6px;
    border: 1px;
    border-radius: 7px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -webkit-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -moz-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
}

.btn-mainmenu:hover{
    background-color: #1aaa1a;
}

.btn-mainmenu:active {
    transform: translateY(1px);
}

.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.66);
    z-index: 999;
}

.pepe-wizard {
    height: 123px;
    position: absolute;
    right: 31px;
    top: 90px;
}