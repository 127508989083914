.optionsmenu {
    height: 100%;
    position: absolute;
    z-index: 100000;
    padding-left: 5%;
    left: 0;
    transition: left 0.3s;
    /* top: 46px; */
}

.optionsmenu.closed {
    left: -1000px;
}

.btn-option {
    width: 100%;
    background-color: #242424;
    padding: 12px;
    margin-top: 10px;
    color: white;
    border: 1px;
    border-radius: 7px;
    font-weight: bold;
    cursor: pointer;
    transition: all 2s;
    box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -webkit-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
    -moz-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.37);
}

.btn-leave {
    background-color: #c42323;
}